
@import "src/assets/custom";

svg.graph {
  width: 100%;
  cursor: grab;
}

.dimmer.active .dimmer-content {
  opacity: 0.4;
}

path.edge {
  stroke: $graph-primary;
  stroke-width: 0.5px;
  stroke-opacity: 0.07;
  fill-opacity: 0;
}

path.strongly-connected {
  stroke: $graph-primary;
  stroke-width: 0.7px;
  stroke-opacity: 0.25;
}

path.failing {
  stroke: $red;
}

path.outgoing {
  stroke: #fec601;
  stroke-opacity: 0.9;
  stroke-width: 1.3px;
}

path.incoming {
  stroke: #73bfb8;
  stroke-opacity: 0.9;
  stroke-width: 1.3px;
}

circle.active {
  fill: $graph-primary;
}

circle.transitive {
  fill: $graph-primary;
}

circle.selected {
  stroke: $yellow;
  opacity: 0.6;
}

circle.failing {
  fill: $red;
}

circle.target {
  stroke: #fec601;
  stroke-opacity: 1;
}

circle.source {
  stroke: #73bfb8;
  stroke-opacity: 1;
}

circle {
  stroke: $white;
  fill: $gray-100;
  cursor: pointer;
  stroke-width: 1.5px;
}

text {
  fill: $graph-primary;
  font-weight: 400;
}

.failing {
  fill: $red;
  opacity: 0.7;
}

.selected {
  font-weight: bold;
}

.rect {
  opacity: 0.8;
}

.rect-selected {
  stroke: yellow;
  stroke-width: 1.5;
}
